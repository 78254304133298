.detailsFormContainer{
	width: 98%;
	margin: 1% auto 2% auto !important;
	text-align: center;
}

.section{
	margin: 10px;
	text-align: center;
}

.sectionLabel{
	font-weight: bold;
	font-size: medium;
	text-align: left;
	margin-left: 6%;
}

.fieldContainer{
	width: 100%;
	margin: 2.5% auto;
	text-align: center;
}

.fieldLabel{
	font-weight: bold;
	font-size: medium;
	text-align: center;
	position: relative;
}

.emptyErrorMessage{
	color: red;
	font-size: 11px;
	font-weight: lighter;
	float: right;
	right: 3%;
	position: absolute;
}

.field{
	width: 90%;
	margin-left: 0.5%;
}

.emptyFieldError{
	border: red solid 1px;
}

.buttonText{

}

.footer{
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin-right: 10%;
	margin-top: 5%;
	margin-bottom: 3%;
}

.floatingFooter{
	margin-top: 0;
	position: fixed;
	bottom: 4%;
	right: 7%;
	display: flex;
}

.submitButton{
	margin-right: 0 !important;
}
