@import 'styles/colors.scss';

:global(.ant-collapse) > :global(.ant-collapse-item):last-child{
	border: none;
}

.studentDetailsPanel :global(.ant-collapse-content) > :global(.ant-collapse-content-box){
	padding: 0 10px;
}

.studentDetailsContainer{
	display: grid;
	grid-template-columns: auto auto;
	grid-row-gap: 10px;
	border: 1px solid $primary-color;
	padding: .5rem 1rem .5rem  1rem;
	width: 100%
}

.studentDetailsLabel{
	font-weight: bold;
	text-align: center;
	padding-right: 2.5px;
	border-bottom: $primary-color solid 1px;
	overflow-wrap: anywhere;
	&:nth-last-child(2){
		border-bottom: none;
	}
	&:first-child{
		padding-top: 2px;
	}
}

.studentDetailsValue{
	text-align: center;
	padding-left: 2.5px;
	border-bottom: $primary-color solid 1px;
	overflow-wrap: anywhere;
	&:last-child{
		border-bottom: none;
	}
	&:nth-child(2){
		padding-top: 2px;
	}
}
