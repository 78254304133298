@import "styles/colors.scss";

.PageHeader{
	background: #00afaf !important;
	width: 100%;
	& :global(.ant-page-header-heading-title){
		color: $primary-color;
		font-size: 2em;
	}
}

.backIcon{
	color: #282c34;
	border-radius: 100%;
	font-size: 20px ;
	padding: 5px;
}

.backIcon:hover{
	background: rgba(255,255,255, 0.15);
	color: #080c14
}
