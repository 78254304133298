@import 'styles/colors.scss';

.createAccountContainer{
	height: 100%;
	width: 100%;
}

.headingSection{
	background: rgb(0,21,41);
	position: fixed;
	display: flex;
	align-content: center;
	flex-direction: column;
}

.formSection{
	background: rgb(29, 167, 167);
	position: fixed;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.fullHeadingSection{
	left: 0;
	width: 30%;
	height: 100%;
	justify-content: flex-start;
}

.fullFormSection{
	right: 0;
	width: 70%;
	height: 100%;
	justify-content: flex-start;
}

.mobileHeadingSection{
	top:0;
	height: 30%;
	width: 100%;
}

.backButton{
	position: absolute !important;
	top: 2vh;
	left: 2vh;
}

.backButtonLabel{
	color: $secondary-color;
	font-size: xx-large;
}

.mobileBackButtonLabel{
	font-size: x-large;
}

.mobileFormSection {
	bottom: 0;
	height: 70%;
	width: 100%;
}

.logoContainer{
	display: flex;
	align-items: center;
	flex-direction: column;
}

.mobileLogoContainer{
	width: 100%;
	margin: 2% auto 0 auto;
}

.fullLogoContainer{
	/*position: fixed;*/
	margin: 10% auto 0 auto;
}

.logoImg{
	width: 25%;
}

.titleContainer{
	margin: auto 0;
}

.fullPageTitleContainer{
	margin: 5% 0;
}

.logoTitle{
	color: white;
	text-align: center;
	font-size: 3vw;
	margin-bottom: 2%;
}

.mobileLogoTitle{
	font-size: 8vw;
}

.pageTitle{
	font-size: 3vw;
	color: rgb(11, 214, 214);
	text-align: center;
	margin-top: 8%;
	margin-bottom: 0;
}

.mobilePageTitle{
	font-size: 7vw;
	margin-top: 0;
	margin-bottom: 2%;
}

.credentialsFormContainer{
	width: 98%;
	margin: auto;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.credentialsField{
	width: 80%;
	margin: 0.5% auto !important;
}

.credentialsSubmitButton{
	width:12%;
	margin-left: auto !important;
	margin-right: 15% !important;
	margin-bottom: 1%;
}

.invisible{
	opacity: 0 !important;
	transition: 500ms;
}

.modalIcon{
	position: absolute;
	font-size: 25px;
}
.modalMessage{
	text-align: center;
	margin-left: 50px;
}

.stepsContainer{
	background-color: rgba(0,0,0,0.4);
}

.step{
	color: white;
}

.mobileStepsContainer{
	padding-top: 2%;
}
