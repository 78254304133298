@import "styles/colors.scss";

.adminDashboard {
	height: 100%;
	display: flex;
	flex-direction: column;
	background-color: $fifth-color;
	overflow: hidden;
}

.dashboardContainer {
	height: 100%;
	display: flex;
	justify-content: space-between;
	padding-top: 2%;
}

.dashboardItem {
	width: 100%;
	height: 100%;
}

.collapseDashboard {
	background-color: rgba(0, 0, 0, 0.1) !important;
	border: none !important;
	overflow: auto;

	& :global(.ant-collapse-item) {
		border-color: $quarternary-color !important;
	}

	& :global(.ant-collapse-content) {
		background-color: $fifth-color !important;
		border-top: none;
	}

	& :global(.ant-collapse-content-box) {
		padding-bottom: 3% !important;
	}
}

.dashboardPanel {
	position: relative;
	& .reloadButton{
		position: absolute;
		right: 0.5em;
		top: 0; bottom: 0;
		margin: auto 0;
	}
	& > :global(.ant-collapse-header) {
		display: flex;
		align-items: center;
	}
}

.dashboardColumn{
	display: flex;
	flex-direction: column;
	width: 100%;
	height: auto;
	overflow: auto;
	.dashboardItem{
		height: 44%;
		flex-shrink: 0;
		overflow: auto;
	}
}
