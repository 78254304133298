.logoImg{
	height: 3em;
	margin: auto 0;
}

.logoContainer{
	margin-right: 1.5%;
	display: flex;
	flex-direction: row;
	cursor: pointer;
	&.centered{
			justify-content: center;
	 }
	&.vertical{
		flex-direction: column;
	}
}

.noLogoContainer{
	margin-right: auto;
}

.logoTitle{
	color: white;
	white-space: nowrap;
	margin-left: 2%;
	margin-bottom: 0;
}
