.sideBar{
	display: flex;
	flex-direction: column;
	height: 100%;
	align-items: flex-start;
}

.userAvatarButton{
	align-self: center;
	margin-top: auto;
	margin-bottom: 20%;
}

.popOverTitle{
	text-align: center;
}

.popoverContent{
	display: flex;
	justify-content: center;
	align-items: center;
}

.logoImg{
	height: 5em;
	margin: 10% auto;
}
