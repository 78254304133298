@import "styles/colors.scss";

.formContainer{
	display: flex;
	height: 100%;
	width: 100%;
	flex-direction: column;
	padding: 1% 2%;
	& > h3{
		text-align: center;
	}
	overflow: auto;
}

:global(.MuiPickersToolbar-toolbar){
		background-color: $primary-color !important;
}

:global(.MuiPickersBasePicker-pickerView){
	background-color: $fifth-color;
	color: $primary-color;
}

:global(.MuiPickersCalendarHeader-iconButton){
	background-color: transparent !important;
}

:global(.MuiPickersCalendarHeader-dayLabel){
	color: $primary-color !important;
}

:global(.MuiPickersDay-current){
	border: $ink-blue solid 2px !important;
}

:global(.MuiPickersDay-daySelected){
	background-color: $tertiary-color !important;
	color: $fifth-color !important;
}

:global(.MuiPickersBasePicker-pickerView){
	max-width: none !important;
}

:global(.MuiPickersCalendar-week){
	justify-content: space-around !important;
}

:global(.MuiPickersCalendarHeader-daysHeader){
	justify-content: space-around !important;
}

.streamSelect{
	width: 100%;
}

.batchSelect{
	width: 100%;
}

.field{
	margin: 1% 0 !important;
}

.submitButton{
	background-color: $ink-blue !important;
	margin-top: 2% !important;
}

.closeAttendanceButton{
	background-color: $error-red !important;
}

.submitButtonText{
	text-transform: none !important;
	color: white !important;
}

.modalIcon{
	position: absolute;
	font-size: 25px;
}

.modalMessage{
	text-align: center;
	margin-left: 50px;
}

.selectList{
	background-color: $fifth-color;
}
