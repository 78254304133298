@import "styles/colors.scss";

.studentDashboard{
	height: 100%;
	display: flex;
	flex-direction: column;
	background-color: $fifth-color;
}

.dashboardContainer{
	height: 100%;
	display: flex;
	padding-top: 2%;
	flex-direction: column;
	align-items: center;
}

.streamsDetails{
	font-size: 1.5rem;
	display: flex;
	justify-content: space-around;
	width: 80%;
	margin-bottom: 20%;
}

.errorMessage{
	color: #e24b4b;
}

.markAttendanceButton{
	background-color: $ink-blue !important;
	color: white !important;
}

.markAttendanceButtonLabel{
	text-transform: none !important;
}
