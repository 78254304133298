@import "styles/colors.scss";

.attendancePage{
	background-color: $fifth-color;
	height: 100%;
}

.body{
	padding: 0.8rem 0;
	overflow-y: scroll;
	background-color: $fifth-color;
}

.attendanceCalendar{
	margin-top: 0.8rem;
}

.toolbar{
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding-left: 2vw;
	padding-right: 3vw;
	height: 2rem;
}

.viewSelectField{
	background-color: $fifth-color;
	//width: 15vw;
	& :global(.MuiInputBase-root){
		height: 2rem;
	}
}

.selectViewList{
	background-color: $fifth-color;
}

.loadingSpinner{
	display: block !important;
	padding-top: 20vh !important;
}

.absentEvent{
	background-color: $pale-red !important;
}
