@import "styles/colors.scss";

.header{
	background-color: $primary-color !important;
	& :global(.ant-page-header-heading-title){
		color: $secondary-color;
	}
}

.headerBackButton{
	color: $secondary-color !important;
}

.body{
	background-color: $fifth-color;
	padding: 2vh 1vw 2vh 1vw;
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	align-items: center;
}

.fieldsContainer{
	display: flex;
	flex-flow: row;
	justify-content: space-around;
	width: 100%;
	margin-bottom: 2vh;
}

.selectField{
	min-width: 40% !important;
}

.selectOption{
	background-color: $fifth-color !important;
}

.downloadButtonRoot{
	background-color: $ink-blue !important;
	& .downloadButtonLabel{
		color: white;
		text-transform: none;
	}
}
