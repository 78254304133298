.container {
	list-style: none;
}

.studentItem{
	padding: 0;
	display: flex;
	text-align: left;
}

