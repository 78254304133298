@import "styles/colors.scss";

.header{
	display: flex;
	flex-direction: row;
	margin-bottom: 0.5rem;
	width: 100%;
	justify-content: center;
	align-items: center;
	position: relative;
	& h2{
		margin-bottom: 0;
	}
	& .reloadButton{
		position: absolute;
		right: 1rem;
	}
}

.container{
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	& h2{
		color: $tertiary-color;
	}
}

.body{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 86%;
	width: 100%;
	flex-flow: column;
}

.loader{
	margin-top: 25%;
}

.listContainer{
	height: 100%;
	width: 97%;
	overflow: auto;
}

.errorMessage{
	margin-top: 27%;
	color: #e24b4b !important;
}

.registeredStudentList{
	background-color: transparent !important;
	border: none !important;
	overflow: scroll;
	& :global(.ant-collapse-content-box){
		padding-top: 0 !important;
	}
}


.registeredStudentItem{
	border-color: $secondary-color !important;
	background-color: rgba(255,255,255,0.4) !important;
	&:last-child{
		border: none;
	}
	& :global(.ant-collapse-content){
		background-color: transparent;
		color: $tertiary-color;
		border: none !important;
	}
}

:global(.ant-collapse-content) > :global(.ant-collapse-content-box){
	padding: 5px 0 0 0;
}
