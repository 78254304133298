@import "styles/colors.scss";

.calendar{
	height: 75vh;
	margin-left: 1vw;
	margin-right: 1vw;
	& :global(.rbc-date-cell) {
		color: $primary-color;
	}
	& :global(.rbc-header){
		color: $secondary-color;
		background-color: $tertiary-color;
		height: 1.8rem;
		font-size: 1rem;
	}
	& :global(.rbc-off-range){
		color: $tertiary-color;
	}
	& :global(.rbc-toolbar) button:hover{
		background-color: $tertiary-color;
		color: $secondary-color
	}
	& :global(.rbc-toolbar) button:active{
		background-color: $tertiary-color;
		color: $secondary-color
	}
	& :global(.rbc-active){
		background-color: $tertiary-color !important;
		color: $secondary-color !important;
	}
	& :global(.rbc-off-range-bg){
		background-color: rgba(0,0,0,0.2);
	}
	& :global(.rbc-today){
		background-color: rgba(255,255,255,0.3);
	}
	& :global(.rbc-header) + :global(.rbc-header){
		border-color: $secondary-color !important;
	}
	& :global(.rbc-toolbar-label){
		font-size: 2em;
	}
	& :global(.rbc-date-cell)>a{
		pointer-events: none;
	}
	& *{
		border-color: $tertiary-color !important;
	}
	& :global(.rbc-event){
		background-color: $lighter-secondary-color;
		border: solid 1px;
		color: black;
	}
	& :global(.rbc-row-segment){
		padding: 0 0.2rem 0 0.2rem;
	}
}

.eventModal> :global(.ant-modal-content){
	border: solid 1px $primary-color;
	background-color: $fifth-color;
	& :global(.ant-modal-close){
		color: $light-secondary-color;
	}
}

.eventModal :global(.ant-modal-header){
	background-color: $primary-color;
	& :global(.ant-modal-title){
		color: $light-secondary-color;
	}
}

.modalContainer{
	display: flex;
	flex-direction: column;
	align-items: center;
}

.modalSection{
	display: flex;
	justify-content: space-around;
	align-items: flex-start;
	width: 100%;
	&:not(:last-child){
		padding-bottom: 0.8rem;
		margin-bottom: 0.8rem;
		border-bottom: 1px solid $tertiary-color;
	}
}

.modalDetail{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	&:not(:last-child){
		padding-right: 1.6rem;
	}
}

.modalDetailLabel{
	font-size: 1rem;
	font-weight: bold;
	white-space: nowrap;
}

.modalDetailValue{
	text-align: center;
}
