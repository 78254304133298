@import "styles/colors.scss";

.header{
	display: flex;
	flex-direction: row;
	margin-bottom: 0.5rem;
	width: 100%;
	justify-content: center;
	align-items: center;
	position: relative;
	& h2{
		margin-bottom: 0;
	}
	& .reloadButton{
		position: absolute;
		right: 1rem;
	}
}

.container{
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
}

.container h2{
	color: $tertiary-color;
}

.bodyContainer{

}

.loader{
	margin-top: 25%;
}

.listContainer{
	height: 86%;
	width: 97%;
	overflow: auto;
}

.errorMessage{
	margin-top: 27%;
	color: #e24b4b !important;
}

.list{
	width: 100%;
	background-color: transparent !important;
	border: none !important;
	overflow: scroll;
	& :global(.ant-collapse-content-box){
		padding-top: 0 !important;
	}
}

.streamItem{
	border-color: $secondary-color !important;
	background-color: rgba(255,255,255,0.4) !important;
	&:last-child{
		border: none;
	}
	& :global(.ant-collapse-content){
		background-color: transparent;
		color: $tertiary-color;
		border: none !important;
	}
}

.listItem{
	border-color: $secondary-color !important;
	background-color: rgba(255,255,255,0.2) !important;
	&:last-child{
		border: none;
	}
	& :global(.ant-collapse-content){
		background-color: transparent;
		color: $tertiary-color;
		border: none !important;
	}
}

:global(.ant-collapse-content) > :global(.ant-collapse-content-box){
	padding: 5px 0 0 0;
}

.batchStudentHeading{
	width: 100%;
}

.batchActionsContainer{
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin-top: 1vh !important;
}

.batchActionButton{
	padding: .5vh .5vw !important;
}

.removeBatchButton{
	border-color: $error-red !important;
	margin-left: .5vw !important;
}

.removeBatchButtonLabel{
	color: $error-red;
}

.reassignStudentsButton{
	border-color: $tertiary-color !important;
	margin-right: .5vw !important;
}

.reassignStudentsButtonLabel{
	color: $primary-color;
}

.batchItemBody{
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.removeBatchModalBody{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.downloadAttendanceNote{
	margin-top: 2vh;
	display: flex;
	justify-content: center;
}

.downloadAttendanceNoteText{
	margin-left: .5vw;
}

.downloadAttendanceButton{
	margin: 1vh auto 0 auto;
}

.inactiveBatchMessage{
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding-bottom: 4%;
	color: $error-red;
}
