@import "styles/colors.scss";

.registeredStudentDescription{

}

.streamDetailsContainer{
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding-left: 5%;
	padding-bottom: 4%;
}

.statusMessageContainer{
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding-bottom: 4%;
}

.inactiveStudentMessage{
	color: $error-red;
}

.studentActionButton{
	padding: .5vh .5vw !important;
}

.addStreamButton{
	border-color: $tertiary-color !important;
	margin-right: .5vw !important;
}

.addStreamButtonLabel{
	color: $primary-color;
}

.removeStudentButton{
	border-color: $error-red !important;
	margin-left: .5vw !important;
}

.removeStudentButtonLabel{
	color: $error-red;
}

.studentDetailsCollapseContainer{
	background-color: rgba(255,255,255,0.3) !important;
	border: none !important;
	& :global(.ant-collapse-content-box){
		background-color: transparent;
		padding-top: 0 !important;
	}
}

.studentDetailsPanel{
	border: none !important;
}

.studentActionsContainer{
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	padding-bottom: 4%;
}
