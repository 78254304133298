$primary-color: rgb(0,21,41);
$secondary-color: rgb(29, 167, 167);
$tertiary-color: #162f46;
$quarternary-color: #2f868e;
$fifth-color: #81e7d2;
$ink-blue: rgb(41,127,245);
$light-secondary-color: #00afaf;
$error-red: #f34a4c;
$lighter-secondary-color: #00cfcf;
$pale-red: #ff7071
