.menuLabel{

}
.menuItem{

}
.sideBarMenuItem{
	padding-left: 10px !important;
	/*text-align: center;*/
	padding-right: 2px !important;
}

.sideBarMenuLabel{
	font-size: 13px;
}
