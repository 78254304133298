@import 'styles/colors.scss';

.backIcon{
	color: $secondary-color;
}

.header{
	background-color: $primary-color !important;
	& :global(.ant-page-header-heading-title){
		color: $secondary-color;
	}
}

:global(.ant-page-header-heading-title){
	color: white;
	font-weight: normal;
}

.formContainer{
	background-color: $secondary-color;
}

.modalIcon{
	position: absolute;
	font-size: 25px;
}
.modalMessage{
	text-align: center;
	margin-left: 50px;
}
