@import "styles/colors.scss";

.loginPage{
	height: 100%;
	width: 100%;
	display: flex;
}

.logoSection{
	background: rgb(0,21,41);
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.horizontalLogoSection{
	height: 55%;
	top:0;
	width: 100%;
}

.verticalLogoSection{
	height: 100%;
	left:0;
	width: 60%;
}

.backButton{
	position: absolute !important;
	top: 2vh;
	left: 2vh;
}

.backButtonLabel{
	color: $secondary-color;
	font-size: xx-large;
}

.mobileBackButtonLabel{
	font-size: x-large;
}

.logoContainer{
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.logoImg{
	height: 40%;
	cursor: pointer;
}

.logoText{
	color: white;
	text-align: center;
	cursor: pointer;
}

.fullLogoText{
	font-size: 350%;
}

.mobileLogoText{
	font-size: 250%;
}

.loginSection{
	background: rgb(7, 167, 167);
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	flex-direction: column;
}

.horizontalLoginSection{
	height: 45%;
	width: 100%;
	bottom:0;
}

.verticalLoginSection{
	height: 100%;
	width: 40%;
	right:0;
}

.loginContainer{
	margin-top: 1%;
	width: 70%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.email{
	margin: 1.5% 0;
}

.password{
	margin: 1.5% 0;
}

.loginOptions{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}
.loginSubmit{
	margin: 3% 0;
	width: 70%;
}

.signupMessageContainer{
	margin-top: 2%;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.dontHaveAccount{
	color: white;
	margin: auto 0;
	margin-right: 1%;
}
/*Meant for google login*/
/*.loginButton{*/
/*	width: 100%;*/
/*	cursor: pointer;*/
/*	display: flex;*/
/*	flex-direction: row;*/
/*	align-items: center;*/
/*}*/

/*.googleLogo{*/
/*	height: 20px;*/
/*	margin: auto 3% auto 0;*/
/*	float:left;*/
/*}*/

/*.loginButtonText{*/
/*	font-size: 1.5em;*/
/*	white-space: normal;*/
/*	margin: 0 auto;*/
/*}*/
