.container{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
}

.attendanceLabel{
	padding-left: 0.2rem;
}
