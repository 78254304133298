.topBar{
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
}

.userAvatar{
	margin-top: auto;
	margin-bottom: auto;
}

.userAvatarButton{
	height: 100%;
	margin-right: 20px;
}

.popOverTitle{
	text-align: center;
}

.popoverContent{
	display: flex;
	justify-content: center;
	align-items: center;
}
