@import "styles/colors.scss";

.container{
	padding-top: 1rem;
}

.table{
	margin: 2rem auto 0 auto;
	width: 95vw;
	text-align: center;
	border: 1px $tertiary-color solid;
}

.headerGroup{
	background-color: $primary-color;
	color: $secondary-color;
}

.header:not(:last-child){
	border-right: 1px $secondary-color solid;
}

.header{
	white-space: nowrap;
}

.row:not(:last-child){
	border-bottom: 1px $quarternary-color solid;
}

.cell{
	display: flex;
	align-items: center;
	justify-content: center;
	&:not(:last-child){
		border-right: 1px $quarternary-color solid;
	}
}

.toolbar{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 2vw;
	padding-right: 3vw;
	height: 2rem;
}

.field{
	background-color: $fifth-color;
	& :global(.MuiInputBase-root){
		height: 2rem;
	}
}

.selectField{
	background-color: $fifth-color;
}

.pageSelectToolbar{
	display: flex;
	align-items: center;
}

.pageIndexSelectContainer{
	display: flex;
	font-size: 1rem;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	//noinspection CssUnknownProperty
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
}

.columnHeader{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0.4rem;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	//noinspection CssUnknownProperty
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
}

.sortIcon{
	margin: 0 0.8rem 0 0.8rem;
	height: 1rem !important;
}

.pageSelectButton {
	&:not(:first-child) {
		margin-left: 0.6rem !important;
	}

	&:not(:last-child) {
		margin-right: 0.6rem !important;
	}
}

.filtersContainer{
	display: flex;
}

.filter:not(:last-child){
	padding-right: 1.6rem;
}

.hoursCountContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 1rem;
}

.hoursCount {
	display: flex;
	flex-direction: row;
}
