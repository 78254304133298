@import 'styles/colors.scss';

.header{
	background: $primary-color !important;
	& :global(.ant-page-header-heading-title){
		color: $secondary-color !important;
		font-size: 1.7em;
	}
}

.backIcon{
	color: $quarternary-color
}

.content{
	background-color: $secondary-color;
	height: 100%;
	padding: 5%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	& h2,h3{
		color: $tertiary-color;
		text-align: center;
	}
}
.emailInput{
	margin: 1.5% 0;
}

.resetPasswordButton{
	margin: 1.5% 0;
}
