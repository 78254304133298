@import 'styles/colors.scss';

body{
	background-color: $primary-color;
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

h1{
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	//noinspection CssUnknownProperty
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
}

.homePageContainer{
	//overflow: auto;
	overflow-y: scroll;
	width: 100vw;
}

.homeSection{
	background-color: $primary-color;
	height: 100vh;
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	position: relative;
}

.logoContainer{
	text-align: center;
	height: 40vh;
	margin-top: 1%;
}

.mobileLogoContainer{
	height: 30vh;
}

.logo{
	margin-top: 5vh;
	height: 30vh;
	animation: rotation 25s infinite linear;
}

.mobileLogo{
	height: 20vh;
}

.homeTextContainer{
	height: 60vh;
	position: relative;
	text-align: center;
}

.mobileHomeTextContainer{
	height: 70vh;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.title{
	color: $secondary-color;
	overflow: visible;
	text-align: center;
	margin-bottom: 0;
}

.fullTitle{
	font-size: 6em;
}

.mobileTitle{
	font-size: 3em;
}

.tagLine{
	color: $fifth-color;
	border-radius: 8px;
	border: 1px solid $fifth-color;
	display: inline-block;
	padding-left: 1%;
	padding-right: 1%;
}

.mobileTagLine{
	font-size: 1.3em;
}

.homeDescription{
	margin: auto 0;
	color: white;
	text-align: center;
}

.mobileHomeDescription{
	font-size: 1.3em;
	padding-left: 2vw;
	padding-right: 2vw;
}

.streams{
	margin-top: 1%;
}

.mobileStreams{
	font-size: 1.3em;
}

.streamText{
	display: inline-block;
	color: $secondary-color;
	font-size: 0.9em;
}

.streamDivider{
	margin-left: 1%;
	margin-right: 1%;
}

.descriptionSectionsContainer{
	background-color: $secondary-color;
	overflow: auto;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}

.section{
	height: 30vh;
	width: 93vw;
	border-radius: 8px;
	display: flex;
	align-items: center;
	background: rgba(0, 0, 0, 0.15) no-repeat;
}

.flippedSection{
	flex-direction: column;
	background: rgba(0, 0, 0, 0.3);
}

.flippedSection h1{
	margin-top: 3px;
}

.flippedSection p{
	text-align: center;
	margin: auto 0;
}

.mobileSection{
	background-size: 150%;
	background-position: right center;
}

.leftText{
	flex-direction: row;
	background-position: right center;
	background-repeat: repeat-x;
}

.rightText{
	flex-direction: row-reverse;
	background-repeat: no-repeat;
}

.sectionText{
	width: 50vw;
	height: 28vh;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.mobileSectionText{
	//width: 60vw;
}

.sectionImage{
	width: 41vw;
	height: 28vh;
	border: solid black 1px;
}

.sectionTitle{
	color: $tertiary-color;
	margin-bottom: 0.5vh;
}

.mobileSectionTitle{
	font-size: 1.7em;
}

.flipSideSectionTitle{
	white-space: nowrap;
}

.sectionDescription{
	width: 90%;
	font-size: 1.3rem;
	color: white
}

.mobileSectionDescription{
	font-size: 0.85rem;
}

.whySection{
	height: 100vh;
	width: 100vw;
	text-align: center;
	display: flex;
	flex-direction: column;
	position: relative;
}

.whyImage{
	position: absolute;
	height: 100%;
	width: 100%;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	z-index: -1;
}

.whyTitle{
	color: $primary-color;
	font-size: 3em;
	margin-top: 2vh;
}

.mobileWhyTitle{
	font-size: 2em;
}

.reasonsContainer{
	margin: auto 0;
	display: flex;
	flex-direction: row;
	width: 100vw;
	height: 80vh;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
}

.carouselItemContainer{
	height: 80vh;
}

:global(.carousel) :global(.slide){
	background: transparent !important;
}

.reason{
	border-radius: 8px;
	background: rgba(255, 255, 255, 0.30);
	color: $tertiary-color;
	height: 31vh;
	width: 40vw;
	margin: 0 1vw 1vh 1vw;
	font-size: 1.2em;
	padding: 1%;
	display: flex;
	text-align: center;
	align-items: center;
	flex-direction: column;
}

.mobileReason{
	height: 70vh;
	width: 90vw;
	margin: 0 auto;
	background: rgba(0, 0, 0, 0.35);
	color: white;
	font-size: 1.5em;
}

.reasonTitle{
	margin-bottom: 0;
	color: $tertiary-color;
}

.mobileReasonTitle{
	color: $fifth-color;
}

.reasonText{
	margin: auto 0;
}

.reasonText mark{
	background-color: transparent;
	color: $primary-color;
	font-weight: bold;
}

.mobileReasonText mark{
	color: $fifth-color;
}

.aboutContainer{
	height: 70vh;
	background-color: $secondary-color;
	display: flex;
}

.mobileAboutContainer{
	flex-direction: column;
	align-items: center;
}

.aboutSectionTitle{
	width: 35vw;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.mobileAboutSectionTitle{
	height: 10vh;
	width: 100vw;
	font-size: 0.7rem;
}

.aboutSectionTitle h1{
	color: $tertiary-color;
	font-size: 3em;
	margin-bottom: 0;
}

.aboutListContainer{
	width: 65vw;
	vertical-align: middle;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
}

.mobileAboutListContainer{
	width: 90vw;
	margin: auto 0;
}

.aboutListContainer:not(.mobileAboutListContainer)::before{
	content: '';
	border-left: 2px solid $primary-color;
	position: absolute;
	height: 90%;
	left: 0;
	top: 5%;
}

.aboutList{
	height: 50vh;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	list-style-type: none;
	margin: 0 auto;
	padding-left: 40px;
	padding-right: 40px;
	border: 2px solid $tertiary-color;
	border-radius: 8px;
	background: rgba(0,0,0,0.15);
}

.mobileAboutList{
	height: 55vh;
	padding-left: 10px;
	padding-right: 10px;
}

.aboutBullet{
	font-size: 1.8em;
	color: #d4fff9;
	position: relative;
	text-align: center;
}

.mobileAboutBullet{
	font-size: 1.05rem;
}

.aboutBullet img{
	height: 2em;
}

.aboutBullet p{
	font-size: 0.6em;
	text-align: center;
}

.mobileAboutBullet p{
	font-size: 0.8em;
}

.aboutBullet p a{
	color: $fifth-color;
	text-decoration: underline;
	font-weight: bold;
}

.linksSection{
	height: 10vh;
	background-color: $tertiary-color;
	display: flex;
	justify-content: center;
	align-items: center;
}

.interestedHeading{
	color: $fifth-color;
	text-transform: none;
	font-size: 1.8rem;
	margin-bottom: 0;
}

.mobileInterestedHeading{
	font-size: 1rem;
}

.interestedFillFormButtonContainer{
	height: 2.3rem;
	flex-direction: column !important;
	justify-content: center !important;
	align-items: center !important;
	margin: 0 0 0 25px !important;
	border-color: $fifth-color !important;
}

.mobileInterestedFillFormButtonContainer{
	height: 1.2rem;
	margin: 0 0 0 10px !important;
}

.interestedFillFormButton{

}

.contactSection{
	background-color: $primary-color;
	height: 20vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.contactUsTitleContainer{
	height: 10vh;
	vertical-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.mobileContactUsTitleContainer{
	height: 12vh;
}

.contactUsTitle{
	color: $secondary-color;
	margin: 0 0 0 5vw !important;
}

.mobileContactUsTitle{
	font-size: 2em;
}

.meansOfContactContainer{
	display: flex;
	justify-content: space-around;
	height: 18vh;
}

.mobileMeansOfContactContainer{
	height: 18vh;
	font-size: 0.5rem;
	margin: auto 0;
}

.meansOfContactContainer h2{
	color: $fifth-color;
}

.contactContainer{
	text-align: center;
}

.contact{
	color: $secondary-color !important;
}

.email{
	text-decoration: underline;
}

.phoneNumber{
	text-decoration: underline;
}

.linkedin{
	text-decoration: underline;
}

.mobileLoginButtonText{
	display: flex;
	flex-direction: column;
	width: 100%;
	&>.loginButtonLoginText{
		border-bottom: 1px solid $quarternary-color;
		padding-bottom: 0.2rem;
		margin-bottom: 0.2rem;
	}
}

.desktopLoginButtonText{
	display: flex;
	width: 100%;
	&>.loginButtonLoginText{
		border-right: 1px solid $quarternary-color;
		padding-right: 0.8rem;
		margin-right: 0.8rem;
	}
}
