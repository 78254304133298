@import "styles/colors.scss";

.header{
	display: flex;
	flex-direction: row;
	margin-bottom: 0.5rem;
	width: 100%;
	justify-content: center;
	align-items: center;
	position: relative;
	& h2{
		margin-bottom: 0;
	}
	& .reloadButton{
		position: absolute;
		right: 1rem;
	}
}

.container{
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
}

.container{
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
}

.container h2{
	color: $tertiary-color;
}

.loader{
	margin-top: 25%;
}

.listContainer{
	height: 86%;
	width: 97%;
	overflow: auto;
}

.errorMessage{
	margin-top: 27%;
	color: #e24b4b !important;
}

.emptyListMessage{
	margin-top: 2%;
	color: gray !important;
}

.interestedStudentList{
	background-color: transparent !important;
	border: none !important;
	//overflow: auto;
	height: auto;
}

.interestedStudentItem{
	border-color: $secondary-color !important;
	background-color: rgba(255,255,255,0.4) !important;
	& :global(.ant-collapse-content){
		background-color: transparent;
	}
	&:last-child{
		border: none;
	}
	& > :global(.ant-collapse-content){
		color: $tertiary-color;
		border: none !important;
		padding-top: 0 !important;
	}
}

.interestedStudentItemBody{
	display: flex;
	align-items: center;
	flex-direction: column;
}

:global(.ant-collapse-content) > :global(.ant-collapse-content-box){
	padding-top: 0 !important;
}

.removeInterestedStudentButton{
	border-color: $error-red !important;
	padding: .5vh .5vw !important;
	margin-top: 1vh !important;
}

.removeInterestedStudentButtonLabel{
	color: $error-red;
}

