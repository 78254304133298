@import "styles/colors.scss";

.resetPasswordPage{
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
}

.logo{
	margin: 1% 0;
}

.content{
	height: 100%;
	width: 100%;
	background-color: $fifth-color;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	& > *:not(:last-child){
		margin-bottom: 2%;
	}
	& > h2{
		color: $tertiary-color;
	}
}

.passwordInput{
	width: calc(180px + 30%);
}

.confirmPasswordInput{
	width: calc(180px + 30%);
}

.resetPasswordButton{
	margin-top: 2%;
}
