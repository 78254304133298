@import "styles/colors.scss";

.addStreamDialog{
	background-color: $fifth-color !important;
}

.header{
	background-color: $primary-color !important;
	& :global(.ant-page-header-heading-title){
		font-size: 1.5em;
	}
}

.header :global(.ant-page-header-heading-title){
	color: $fifth-color;
	font-weight: normal;
}

.backIcon{
	color: $secondary-color !important;
}

.formContainer{
	padding: 0 4% 5% 4%;
	overflow: scroll;
}

.formSection{
	position: relative;
	padding-top: 3%;
	&:not(:first-child){
		margin-top: 3%;
	}
	&:not(:first-child):before{
		content: '';
		position: absolute;
		top: 0;
		width: 100%;
		border-top: solid 1px $quarternary-color;
	}
}

.sectionHeader{
	color: $primary-color;
	margin-bottom: 2%;
}

.streamSelectField{
	width: 40%;
}

.newStreamField{
	width: 40%;
	margin-left: 5% !important;
}

.batchSelectField{
	width: 100%;
}


.selectMenuList {
	background-color: $fifth-color;
	overflow: auto;
}

.newBatchDetails{
	display: flex;
	margin-top: 3%;
	justify-content: space-between;
}

.batchYearField{
	width: 20%;
	margin: 0 !important;
}

.batchMonthField{
	width: 30%;
	margin: 0 !important;
}

.batchTopicField{
	width: 40%;
	margin: 0 !important;
}

.studentSelect{
	width: 100%;
	height: 100%;
}

.selectedStudentsContainer{
	margin-top: 2%;
}

.studentChipStudentName{
	font-size: 1rem;
}

.studentChipLabel{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.studentChipLabelContainer{
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.studentChip{
	margin: 1%;
	padding-top: 4% !important;
	padding-bottom: 4% !important;
}

.submitButton{
	margin-top: 2% !important;
	margin-bottom: 4% !important;
	background-color: $ink-blue !important;
	color: white !important;
	float: right;
	margin-right: 2% !important;
}

.disabledSubmitButton{
	background-color: gray !important;
	cursor: not-allowed;
}

.modalIcon{
	position: absolute;
	font-size: 25px;
}

.modalMessage{
	text-align: center;
	margin-left: 50px;
}
