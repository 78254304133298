@import "styles/colors.scss";

.logoContainer{
	margin-top: 2%;
}

.img{
	height: 15rem;
	margin: 1% 0 2% 0;
}

.container{
	width: 90%;
	margin: 2% auto 0 auto;
	background-color: $light-secondary-color;
	text-align: center;
	padding: 1% 10% 3% 10%;
}

.button{
	border-width: 1px !important;
	border-color: $primary-color !important;
}

.buttonText{
	text-transform: none;
	font-size: 1.5em;
}
